var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useState, useCallback, useRef, } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import ContentFilter from '@material-ui/icons/FilterList';
import classnames from 'classnames';
import lodashGet from 'lodash/get';
import { useListContext, useResourceContext } from 'ra-core';
import { FilterButtonMenuItem } from './FilterButtonMenuItem';
import Button from '../../button/Button';
var useStyles = makeStyles({
    root: { display: 'inline-block' },
}, { name: 'RaFilterButton' });
var FilterButton = function (props) {
    var filters = props.filters, classesOverride = props.classes, className = props.className, rest = __rest(props, ["filters", "classes", "className"]);
    var resource = useResourceContext(props);
    var _a = useListContext(props), _b = _a.displayedFilters, displayedFilters = _b === void 0 ? {} : _b, filterValues = _a.filterValues, showFilter = _a.showFilter;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var anchorEl = useRef();
    var classes = useStyles(props);
    var hiddenFilters = filters.filter(function (filterElement) {
        return !filterElement.props.alwaysOn &&
            !displayedFilters[filterElement.props.source] &&
            typeof lodashGet(filterValues, filterElement.props.source) ===
                'undefined';
    });
    var handleClickButton = useCallback(function (event) {
        // This prevents ghost click.
        event.preventDefault();
        setOpen(true);
        anchorEl.current = event.currentTarget;
    }, [anchorEl, setOpen]);
    var handleRequestClose = useCallback(function () {
        setOpen(false);
    }, [setOpen]);
    var handleShow = useCallback(function (_a) {
        var source = _a.source, defaultValue = _a.defaultValue;
        showFilter(source, defaultValue);
        setOpen(false);
    }, [showFilter, setOpen]);
    if (hiddenFilters.length === 0)
        return null;
    return (React.createElement("div", __assign({ className: classnames(classes.root, className) }, sanitizeRestProps(rest)),
        React.createElement(Button, { className: "add-filter", label: "ra.action.add_filter", onClick: handleClickButton },
            React.createElement(ContentFilter, null)),
        React.createElement(Menu, { open: open, anchorEl: anchorEl.current, onClose: handleRequestClose }, hiddenFilters.map(function (filterElement) { return (React.createElement(FilterButtonMenuItem, { key: filterElement.props.source, filter: filterElement, resource: resource, onShow: handleShow })); }))));
};
var sanitizeRestProps = function (_a) {
    var displayedFilters = _a.displayedFilters, filterValues = _a.filterValues, showFilter = _a.showFilter, rest = __rest(_a, ["displayedFilters", "filterValues", "showFilter"]);
    return rest;
};
FilterButton.propTypes = {
    resource: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.node).isRequired,
    displayedFilters: PropTypes.object,
    filterValues: PropTypes.object.isRequired,
    showFilter: PropTypes.func.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
};
export default FilterButton;
